import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { orderBy, padStart } from 'lodash'
import moment from 'moment'

import { Layout, SignUp, Footer, Ticker } from '../components'
import { container, padding, bgImage, type, bgIcon, maxWidth } from '../styles/global'
import { parseACF, resolveSocialIcon } from '../utils'
import { media } from '../styles/utils'

import logoText from '../assets/images/logo-text.svg'
import heroArrow from '../assets/images/hero/hero-text-arrow.svg'
import moreLogo from '../assets/images/more-logo.svg'
import sidebarArrow from '../assets/images/sidebar-text-arrow.svg'

const Scroll = require('react-scroll')
const Element = Scroll.Element
const scroller = Scroll.scroller

const Index = (props) => {
	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)
	
	const replaceTextWithLogo = string => {
		return string.split("PRIVATE EYES")?.[0] + string.split("PRIVATE EYES")?.[1]
	}

	const scrollToRelease = slug => {
		scroller.scrollTo(slug, {
			duration: 700,
			smooth: true,
			offset: -10
		})
	}

	const renderHero = () => {
		return (
			<Hero>
				<LogoBase/>
				<LogoEyes/>

				<Info>
					<Ticker
						content={data.header_message}
					/>

					<Heading>
						Australian music <br />
						<Image image={heroArrow}/> in isolation
					</Heading>
					
					<Description>
						<Icon src={logoText} />
						<div
							dangerouslySetInnerHTML={{__html: replaceTextWithLogo(data?.introduction)}}
						/>
					</Description>
				</Info>

				<BGImage />
				<MobileBGImage />
			</Hero>
		)
	}

	const renderSidebar = () => {
		if (!data.releases) return;
		
		const releases = orderBy(data.releases, 'acf.date', 'asc');

		const items = releases.map((item, i) => {
			const itemNumber = ("0" + (i+1)).slice(-2)
			return (
				<NavItem
					key={i}
					onClick={() => scrollToRelease(item.slug)}
				>
					#{itemNumber} {item.title}
				</NavItem>
			)
		})

		return (
			<Sidebar>
				<Button>
					support
				</Button>

				<Info>
					A Special project by <Icon src={sidebarArrow}/><br />
					<a href="https://bedroomsuckrecords.com/" target="_blank">Bedroom Suck Records</a>
				</Info>

				<Heading>The Releases</Heading>
				<Nav>
					{items}
				</Nav>
			</Sidebar>
		)
	}

	const renderIntro = () => {
		return (
			<Intro>
				<Description
					dangerouslySetInnerHTML={{__html: data.description}}
				/>
			</Intro>
		)
	}

	const renderReleaseLinks = (item) => {
		if (!item.use_vinyl_link && !item.user_other_links) return

		console.log(item)

		const items = item.links.map((item, i) => {
			if (i > 2) return;

            return (
                <ReleaseLink
					key={i}
                    href={item.url}
                    target={'_blank'}
                >
                    <SocialIcon
						key={i}
						type={item.type}
                        src={resolveSocialIcon(item.type)}
                    />
                </ReleaseLink>
            )
		})
		
		return (
			<ReleaseLinks
				className={'release-links'}
			>
				<Heading>
					buy
				</Heading>
				<Links>
					{item.use_vinyl_link && (
						<ReleaseLink
							href={item.vinyl_link}
							target={'_blank'}
							type={'vinyl'}
						>
							vinyl
						</ReleaseLink>
					)}

					{items}
				</Links>
			</ReleaseLinks>
		)
	}

	const renderReleaseImage = (item) => {
		return (
			<ImageWrapper>
				{item.image && (
					<Image
						image={item.image?.sizes?.medium2}
					/>
				)}

				{!item.image && (
					<ComingSoon
						image={require('../assets/images/coming-soon.svg')}
					/>
				)}
			</ImageWrapper>
		)
	}
	
	
	const renderReleaseItems = () => {
		if (!data.releases) return;

		const releases = orderBy(data.releases, 'acf.date', 'asc');

		return releases.map((item, i) => {

			item = {
				...item,
				...item.acf
			}

			return (
				<Release 
					key={i}
					as={Element}
					name={item.slug}
					colour={releaseColours[i]}
				>
					{renderReleaseImage(item)}
					
					<Info>
						<Heading
							dangerouslySetInnerHTML={{__html: `
								PE #${padStart(i + 1, 2, '0')}<br>
								${item.title}
							`}}
						/>

						<Subheading>
							{moment(item.date).format('MMM')}
						</Subheading>
						
						{renderReleaseLinks(item)}
					</Info>
				</Release>
			)
		})	
	}

	const renderCredits = () => {
		return (
			<Credits>
				<Description
					dangerouslySetInnerHTML={{__html: data.credits}}
				/>

				<Info>
					Design & Development by<br />
					<a href="https://morebymore.com/" target="_blank">More Studio</a>
				</Info>

				<MoreLink
					href="https://morebymore.com/" target="_blank"
				>
					<Image
						image={moreLogo}
					/>
				</MoreLink>
			</Credits>
		)
	}
	
	const renderReleases = () => {
		return (
			<Releases>
				<Info>
					<Heading>Releases</Heading>
					<Description
						dangerouslySetInnerHTML={{__html: data.releases_text}}
					/>
				</Info>
				<Listing>
					<Grid>
						{renderReleaseItems()}
						{renderCredits()}
					</Grid>
				</Listing>
			</Releases>
		)
	}

	return (
		<Layout
            meta={data && data.seo}
        >
			<Wrapper>
				{renderHero()}
				{renderSidebar()}

				<Container>
					{renderIntro()}
					{renderReleases()}
				</Container>

				<Footer first_nations={data?.first_nations}/>
			</Wrapper>

			<SignUp signup_message={data?.signup_message} />
		</Layout>	
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Icon = styled.img``
const Label = styled.div``
const Nav = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
`

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	background: white;
`

const Grid = styled.div`
	display: flex;
`

// Heros

const LogoBase = styled.div`
	background-image: url(${require('../assets/images/logo-base.png')});
	width: 180px;
	height: 180px;
	${bgIcon}

	position: absolute;
	top: 24px;
	left: 24px;

	${media.phone`
		width: 90px;
		height: 90px;

		top: 60px;
		left: 14px;
	`}
`

const LogoEyes = styled.div`
	background-image: url(${require('../assets/images/logo-eyes.png')});
	width: 140px;
	height: 90px;
	${bgIcon}

	z-index: 2;
	position: fixed;
	top: 70px;
	left: 44px;

	${media.phone`
		width: 70px;
		height: 45px;

		top: 84px;
		left: 24px;
	`}
`

const BGImage = styled.div`
	background-image: url(${require('../assets/images/hero/hero-bg-image.png')});
	${bgIcon};
	position: absolute;
	top: 54px;
	left: 90px;
	right: 32px;
	bottom: 0;
	mix-blend-mode: exclusion;
	background-size: contain;
	background-position: bottom;

	${media.phone`
		display: none;
	`}
`

const MobileBGImage = styled.div`
	display: none;

	${media.phone`
		display: block;
	`}

	background-image: url(${require('../assets/images/hero/hero1.png')});
	${bgIcon};
	position: absolute;
	top: 0;
	right: 7px;
	bottom: 0;
	width: 280px;
	mix-blend-mode: exclusion;
	background-size: contain;
	background-position: center right;
`

const Hero = styled.div`
	position: relative;
	/* width: 1440px; */
	max-width: 100%;
	height: 100vh;
	min-height: 800px;
	max-height: 900px;
	background-image: url(${props => props.image});  
	${bgImage};

	${media.phone`
		height: initial;
		max-height: initial;
		min-height: initial;
	`}

	${Info} {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		${container};
		${padding};
		mix-blend-mode: exclusion;
		transform: translate3d(0, 0, 0);
		will-change: opacity;
			
		${media.phone`
			position: static;
			mix-blend-mode: exclusion;
		`}

		* {
			color: white;
			position: relative;
			z-index: 1;
			transform: translate3d(0, 0, 0);
			will-change: opacity;
		}

		.text-ticker, ${Heading} {
			margin-left: 41%;

			${media.tablet`
				margin-left: 30%;
			`}

			${media.phone`
				margin-left: 0;
			`}
		}

		.text-ticker {
			margin-top: 12px;

			${media.phone`
				margin-top: 16px;
			`}
		}

		${Heading} {
			margin-top: 39px;
			font-size: 64.4575px;
			line-height: 98%;

			${media.phone`
				margin-top: 229px;
				font-size: 42.9717px;
				line-height: 98%;
			`}

			${Image} {
				display: inline-block;
				width: 37px;
				height: 32px;
				${bgIcon};
			}
		}

		${Description} {
			margin-top: auto;

			${media.phone`
				margin-top: 110px;
			`}

			* {
				display: inline;
			}

			div {
				&, * {
					font-size: 43px;
					line-height: 49px;
					text-align: justify;

					${media.phone`
						font-size: 27.7572px;
						line-height: 33px;
					`}
				}
			}
		}
	}
`

// Sidebar

const sidebarText = css`
	font-size: 22.1818px;
	line-height: 25px;
	color: white;

	${media.phone`
		font-size: 21.1474px;
		line-height: 24px;
	`}
`

const NavItem = styled.div``
const Button = styled.a``
const Sidebar = styled.div`
	position: fixed;
	z-index: 2;
	top: 18px;
	right: 20px;
	width: 228px;
	mix-blend-mode: exclusion;
	
	/* 
	@media (min-width: ${maxWidth+248}px) {
		right: initial;
		left: ${maxWidth}px;
	} */

	${media.tablet`
		position: absolute;
	`}

	${media.phone`
		top: 64px;
		right: 12px;
		width: 200px;
	`}

	${Button} {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 36px 15px 41px 19px;
		border-radius: 20px;

		background: white;
		color: black;
		font-size: 37.7143px;
		line-height: 43px;
		border: 1px solid black;

		&:hover {
			background: black;
			color: white;
			border: 1px solid white;
		}
	}

	${Info}, ${Heading}, ${Nav} {
		padding-left: 10px;

		&, * {
			${sidebarText};
		}
	}

	${Info} {
		margin-top: 22px;

		${media.phone`
			margin-top: 12px;
		`}

		a {
			text-decoration: underline;
		}
	}

	${Heading} {
		margin-top: 39px;
		text-indent: 16px;

		${media.tablet`
			display: none;
		`}
	}

	${Nav} {
		display: flex;
		flex-direction: column;
		max-width: 200px;

		${media.tablet`
			display: none;
		`}

		${NavItem} {
			cursor: pointer;
			padding-left: 20px;
        	text-indent: -23px;

			&:hover {
				text-decoration: underline;
			}
		}
	}
`

// Intro

const Intro = styled.div`
	margin-top: 52px;

	${media.phone`
		display: none;
	`}

	${Description} {
		&, p {
			${type.body}
		}

		max-width: 543px;
	}
`

// Releases

const Listing = styled.div``
const Releases = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	> ${Info} {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 206px;
		margin-bottom: 163px;
		max-width: 100%;

		${media.phone`
			margin-top: 110px;
			margin-bottom: 84px;
		`}

		${Heading} {
			font-size: 200px;
			line-height: 98%;

			${media.phone`
				font-size: 100px;
			`}
		}

		${Description} {
			margin-top: 48px;
			max-width: 701px;
			position: relative;
			text-align: center;

			${media.phone`
				margin-top: 32px;
				
				p:first-letter {
					margin-left: 20px;
				}
			`}

			&, p {
				${type.body}
			}

			&::before {
				position: absolute;
				background-image: url(${require('../assets/images/releases-text-arrow.svg')});
				width: 47px;
				height: 15px;
				${bgIcon};
				content: '';
				left: 0px;
				top: 0;
				transform: translateX(-100%);

				${media.phone`
					width: 28px;
					height: 21px;
					transform: none;
					left: 15px;
				`}
			}
		}
	}

	${Listing} {
		margin: 0 -5px;

		${media.phone`
			margin: 0;
		`}
	}

	${Grid} {
		width: 100%;
		flex-flow: row wrap;

		${media.phone`
			flex-direction: column;
		`}
	}
`

// Release (Grid Item)

const ImageWrapper = styled.div``
const ComingSoon = styled(Image)``

const Release = styled.div`
	display: flex;
	flex-direction: column;
	width: 33.33%;
	padding-left: 5px;
	padding-right: 5px;
	box-sizing: border-box;
	margin-bottom: 10px;

	${media.phone`
		width: 100%;
		padding: 0;
	`}

	${ImageWrapper}, ${Info} {
		border: 2px solid black;
	}

	${ImageWrapper} {
		padding: 40px;
		width: 100%;

		${Image} {
			width: 100%;
			padding-bottom: 100%;
			mix-blend-mode: multiply;
			filter: saturate(0);
		}

		${ComingSoon} {
			width: 100%;
			padding-bottom: 100%;
			${bgIcon}
			background-size: 25%;
		}
	}

	${Info} {
		display: flex;
		flex-direction: column;
		align-items: center;	
		justify-content: center;
		position: relative;
		min-height: 191px;
		border-top: none;
		padding: 20px;
		flex: 1;

		${Heading} {
			font-size: 48px;
			line-height: 100%;
			text-align: center;
			letter-spacing: -0.02em;
		}

		${Subheading} {
			position: absolute;
			top: 0;
			right: 0;
			border-left: 2px solid black;
			border-bottom: 2px solid black;
			padding: 5px 9px;
			${type.body}
			text-transform: uppercase;
		}
	}

	${props => {
		if (props.colour) return css`
			${ImageWrapper}, ${Info} {
				background: ${props.colour};
			}
		`
	}}

	&:hover {
		${ImageWrapper} {
			${Image} {
				mix-blend-mode: initial;
				filter: saturate(1);
			}
		}

		.release-links {
			opacity: 1;
			pointer-events: all;
		}
	}
`

// Release Links

const Links = styled.div``

const ReleaseLinks = styled.div`
	background: black;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${Heading} {
		font-size: 48px;
		line-height: 100%;
		font-style: italic;
		letter-spacing: -0.02em;
		color: white;
	}

	${Links} {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-top: 12px;

		> * {
			margin-top: 12px;
			&:not(:last-child) {
				margin-right: 7px;
			}
		}
	}

	/* Animation */

	pointer-events: none;
	opacity: 0;
`

const ReleaseLink = styled.a`
	border-radius: 50%;
	background: white;
	width: 52px;
	height: 52px;
	padding: 10px;

	display: flex;
	justify-content: center;
	align-items: center;

	${props => {
		if (props.type == 'vinyl') return css`
			width: 103px;
			border-radius: 30px;
			font-size: 24px;
			line-height: 28px;
		`
	}}
`

const SocialIcon = styled.img`
    width: auto;
	height: 100%;
	
	${props => {
		if (props.type == 'apple-music') return css`
			transform: translateY(-1px);
		`
	}}
`

const releaseColours = [
	'#87D364',
	'#FDEC1A',
	'#F4ECD7',
	'#BE5E5E',
	'#BA9639',
	'#3886E2',
	'#B0D85B',
	'#84DBC6',
	'#E29E38',
	'#DBB8FD',

	// Loop

	'#87D364',
	'#FDEC1A',
	'#F4ECD7',
	'#BE5E5E',
	'#BA9639',
	'#3886E2',
	'#B0D85B',
	'#84DBC6',
	'#E29E38',
	'#DBB8FD',
]


// Credits Grid Item

const MoreLink = styled.a``

const Credits = styled.div`
	width: 66.66%;
	padding-left: 5px;
	padding-right: 5px;
	box-sizing: border-box;
	margin-bottom: 10px;

	${media.phone`
		width: 100%;
		padding: 0;
	`}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	p {
		margin: 0;
	}

	${Description} {
		margin-top: 100px;
		font-size: 22.1818px;
		line-height: 25px;

		${media.phone`
			margin-top: 85px;
		`}
	}

	${Info} {
		margin-top: 81px;

		${media.phone`
			margin-top: 52px;
		`}
	}

	${Image} {
		width: 33px;
		height: 33px;
		margin-top: 18px;
		${bgIcon};
	}
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
		}
		
		allWordpressReleases {
			nodes {
			  slug
			  title
			  acf_json
			}
		}
    }
`

export default Index